import { createConsumer, logger } from '@rails/actioncable';

interface Consumer {
  subscriptions: {
    create<TObject extends Partial<SubscriptionOptions>>(channel: Channel, options: TObject)
  }
}

type Channel = string | { channel: string } & Record<string, unknown>

interface SubscriptionOptions {

  // Called once when the subscription is created.
  initialized(): void

  // Called when the subscription is ready for use on the server.
  connected(): void

  // Called when the WebSocket connection is closed.
  disconnected(): void

  // Called when the subscription is rejected by the server.
  rejected(): void

}

export default createConsumer() as Consumer;
