import { KEY_NAMES, KEY_NAMES_LEGACY } from '../aria';
import { emitter } from '../emitter';
import { Replacable } from '../replacable';
import { upgradeAll } from '../ujs';
import { toggleExpanded, toggleHidden } from '../utils';

const SECONDS_TO_SHOW_NOTIFICATION = 7;

type ReplacementEvent =
  | {
      replacable: Replacable;
      root: HTMLElement;
      replacement: never;
      existing: never;
    }
  | {
      replacable: Replacable;
      root: never;
      replacement: HTMLElement;
      existing: HTMLElement;
    };

type ReadEvent = {
  id: string;
};

export class QuickNotifications {
  private readonly actionClear: HTMLButtonElement;
  private reads: string[] = [];
  private requiresNotification: string[] = [];
  private notifiedOrOld: string[] | undefined;
  private lastGeneratedAt: string | undefined;
  private actionClose: HTMLButtonElement | null;
  private notifyTimeout: number | undefined;
  private redirectOnClick: boolean;

  constructor(
    private readonly node: HTMLElement,
    private readonly trigger: HTMLElement
  ) {
    if (this.trigger.getAttribute('aria-controls') !== this.node.id) {
      throw new Error(
        `Expected trigger and node to be connected. ID of node is ${
          node.id
        }, trigger controls: ${trigger.getAttribute('aria-controls')}`
      );
    }

    this.actionClear = this.node.querySelector<HTMLButtonElement>(
      'button[data-action="clear"]'
    )!;
    this.actionClose = this.node.querySelector<HTMLButtonElement>(
      'button[data-action="close"]'
    );

    this.redirectOnClick = trigger.hasAttribute('data-open-page');
    this.onRefresh = this.onRefresh.bind(this);
    this.onReadNotification = this.onReadNotification.bind(this);
    this.onToggle = this.onToggle.bind(this);
    this.onClearAll = this.onClearAll.bind(this);
    this.onClose = this.onClose.bind(this);
    this.onOutsideClick = this.onOutsideClick.bind(this);
    this.onEscape = this.onEscape.bind(this);
    this.onFocusMoving = this.onFocusMoving.bind(this);
    this.markAsRead = this.markAsRead.bind(this);

    if (!this.redirectOnClick) {
      this.trigger.addEventListener('click', this.onToggle);
    }
    this.actionClear?.addEventListener('click', this.onClearAll);
    this.actionClose?.addEventListener('click', this.onClose);
    document.addEventListener('click', this.onOutsideClick);
    document.addEventListener('keydown', this.onEscape);
    document.addEventListener('focusin', this.onFocusMoving);

    emitter.on('notification:read', this.onReadNotification);
    emitter.on('notifications:refreshed', this.onRefresh);
  }

  public destroy(): void {
    if (!this.redirectOnClick) {
      this.trigger.removeEventListener('click', this.onToggle);
    }
    this.actionClear.removeEventListener('click', this.onClearAll);
    this.actionClose?.removeEventListener('click', this.onClose);
    document.removeEventListener('click', this.onOutsideClick);
    document.removeEventListener('keydown', this.onEscape);
    document.removeEventListener('focusin', this.onFocusMoving);

    emitter.off('notifications:refreshed', this.onRefresh);
    emitter.off('notification:read', this.onReadNotification);

    this.notifyTimeout && clearTimeout(this.notifyTimeout);
  }

  public open(): void {
    if (this.active) {
      return;
    }

    this.active = true;
    this.activeNotification = false;
    this.requiresNotification.splice(0, this.requiresNotification.length);

    const firstElement = this.node.querySelector('[tabindex]');
    if (firstElement) {
      (firstElement as HTMLElement).focus();
    }
  }

  public close(): void {
    if (!this.active) {
      return;
    }

    this.active = false;
    this.trigger.focus();
  }

  private get active(): boolean {
    return this.node.classList.contains('-open');
  }

  private set active(next: boolean) {
    this.node.classList.toggle('-open');
    toggleExpanded(this.trigger, next);
  }

  private get activeNotification(): boolean {
    return !!document
      .querySelector('[data-target="notification"]')
      ?.classList.contains('active');
  }

  private set activeNotification(next: boolean) {
    const target = document.querySelector('[data-target="notification"]');
    if (!target) {
      return;
    }

    target.classList.toggle('active', next);

    if (next) {
      target.removeAttribute('aria-hidden');
    } else {
      target.setAttribute('aria-hidden', 'true');
    }
  }

  private onEscape(e: KeyboardEvent): void {
    if (!this.active) {
      return;
    }

    if (e.key === KEY_NAMES.ESC || e.key === KEY_NAMES_LEGACY.ESC) {
      this.close();
    }
  }

  private onOutsideClick(e: Event): void {
    if (!this.active || !e.target) {
      return;
    }

    if (!(e.target instanceof HTMLElement)) {
      return;
    }

    // Click was inside
    if (this.node.contains(e.target) || this.node === e.target) {
      return;
    }

    this.close();
  }

  private onToggle(e: Event): void {
    e.preventDefault();
    e.stopPropagation();

    if (this.active) {
      this.close();
    } else {
      this.open();
    }
  }

  private onClose(e: Event): void {
    this.close();
  }

  private onClearAll(e: Event): void {
    this.node
      .querySelectorAll('[data-invalidated]')
      .forEach((node) => node.remove());

    this.actionClear.disabled = true;
  }

  private onRefresh(event: unknown): void {
    const { replacement, root } = event as ReplacementEvent;

    if (!root) {
      console.log('Refreshed single notification', replacement);
      this.reads.forEach((read) => {
        this.markAsRead(read, replacement);
      });
      return;
    }

    const generatedRoot = root.hasAttribute('data-generated-at')
      ? root
      : root.querySelector('[data-generated-at]');
    const generatedAt = generatedRoot?.getAttribute('data-generated-at');

    if (generatedAt === this.lastGeneratedAt) {
      // The data may be refreshed, but it is stale. Apply all local changes to
      // the data until it is fresh again.
      this.reads.forEach((read) => {
        this.markAsRead(read);
        root && this.markAsRead(read, root);
      });

      this.refreshBadge();
    } else {
      // The data is fresh. Throw away all local changes and use the remote
      // data.
      this.reads.splice(0, this.reads.length);
      this.node
        .querySelectorAll('[data-notification]')
        .forEach((notification) => {
          if (!notification.hasAttribute('data-unseen')) {
            this.reads.push(notification.id);
          }
        });

      this.lastGeneratedAt = generatedAt || undefined;
      this.actionClear.disabled =
        this.node.querySelectorAll('[data-invalidated]').length === 0;

      this.maybeNotify();
      this.refreshBadge();
    }
  }

  private onReadNotification(event: unknown): void {
    if (!event || typeof event !== 'object') {
      return;
    }

    const { id } = event as ReadEvent;
    this.reads.push(id);
    this.markAsRead(id);
    this.refreshBadge();

    // Remove marked notifications from those that require reads
    const index = this.requiresNotification.indexOf(id);
    if (index !== -1) {
      this.requiresNotification.splice(index, 1);
    }
  }

  private onFocusMoving(e: FocusEvent): void {
    if (!e.target || !(e.target instanceof Element)) {
      return;
    }

    // We don't care
    if (!this.active) {
      return;
    }

    // Focus still inside
    if (
      this.node.contains(e.target) ||
      this.node === e.target ||
      this.trigger.contains(e.target) ||
      this.trigger === e.target
    ) {
      return;
    }

    this.close();
  }

  private markAsRead(id: string, source = this.node): void {
    const items = document.querySelectorAll<HTMLElement>(
      `[data-notification="${id}"]`
    );

    items.forEach((item: unknown) => {
      const notification = item as HTMLElement;
      notification.classList.toggle('--unread', false);
      notification.classList.toggle('--read', true);
      notification.removeAttribute('data-unseen');

      const marker = notification.querySelector('.action.--mark');
      if (marker) {
        marker.setAttribute('hidden', '');
      }
    });
  }

  private refreshBadge(source = this.node): void {
    const unseenCount = source.querySelectorAll('[data-unseen]').length;
    this.trigger.setAttribute('data-unseen', String(unseenCount));
  }

  private maybeNotify(): void {
    // This has been the first load, don't show anything.
    if (this.notifiedOrOld === undefined) {
      this.notifiedOrOld = [];
      this.node.querySelectorAll('[data-unseen]').forEach((notification) => {
        this.notifiedOrOld!.push(
          notification.getAttribute('data-notification')!
        );
      });
      return;
    }

    this.node.querySelectorAll('[data-unseen]').forEach((notification) => {
      const id = notification.getAttribute('data-notification')!;
      const index = this.notifiedOrOld!.indexOf(id);

      if (index === -1) {
        this.requiresNotification.push(id);
        this.notifiedOrOld!.push(id);
      }
    });

    if (this.requiresNotification.length === 0 || this.activeNotification) {
      return;
    }

    const notification = this.requiresNotification.shift()!;
    this.notify(notification);
  }

  private notify(id: string): void {
    if (this.active) {
      console.log('[notifications] not notifying because pull-out is active');
      return;
    }

    const source = this.node.querySelector(`[data-notification="${id}"]`);
    const target = document.querySelector('[data-target="notification"]');

    if (!source || !target) {
      console.log(
        '[notifications] not notifying because source or target is missing',
        { source, target }
      );
      return;
    }

    while (target.lastElementChild) {
      target.removeChild(target.lastElementChild);
    }

    const clone = source.cloneNode(true);
    target.appendChild(clone);
    upgradeAll();

    console.log('[notifications] showing notification (live)', { target });

    this.activeNotification = true;

    this.notifyTimeout = setTimeout(() => {
      // TODO: check if element has mouse over or focus. If so, do NOT hide
      //       this notification just yet but keep it visible. You can either
      //       choose to just re-check ~1 second later, or temporarily add a
      //       handler for blur and mouseout.

      this.activeNotification = false;

      this.notifyTimeout = setTimeout(() => {
        target.querySelector(`[data-notification="${id}"]`)?.remove();
        this.maybeNotify();
      }, 500);
    }, SECONDS_TO_SHOW_NOTIFICATION * 1000);
  }
}
