/**
 * Makes a component marked with [data-toggleable] toggle some content on the
 * DOM.
 *
 * Similar to overflowable, but doesn't toggle a menu, just some content.
 *
 * Buttons that toggle visability or a different element, such as accordions,
 * "more content", etc. can use this component.
 *
 * In order to make an element toggleable, add the [data-toggleable] attribute
 * to the button. It MUST also have [aria-expanded], indicating the current
 * state, and [aria-controls="id"] pointing to a unique ID to the element it
 * will toggle on the page.
 *
 * The element to toggle needs to have an "id", and by default uses the `hidden`
 * attribute to determine if something is hidden, or not. It also must be
 * focusable.
 *
 * If the value of [data-toggleable] is set, it must be a JSON encoded object,
 * with the following, optional, keys and values:
 *
 * {
 *   form: 'query-param-to-flip' | true | false,
 *   event: 'event-to-listent-to',
 *   className: 'class name to flip on target'
 * }
 *
 * @example
 *
 * <section>
 *   <button
 *     type="button"
 *     data-toggleable
 *     aria-controls="content"
 *     aria-expanded="false">
 *     Toggle content
 *   </span>
 *   <div id="content" hidden tabindex="-1">
 *      Content to hide/show on toggle
 *   </div>
 * </section>
 */

import { emitter } from './emitter';
import { register, upgrade } from './ujs';
import { toggleExpanded, toggleHidden } from './utils';

export type ToggleEvent = { collapsed: boolean };
export type Options = Partial<{
  form?: string | boolean;
  event?: string;
  className?: string;
}>;

export class Toggleable {
  private readonly target: HTMLElement;

  private form: HTMLFormElement | undefined;
  private formQueryParam: string | undefined;
  private className: string;
  private event: string | undefined;

  constructor(
    private readonly component: HTMLElement,
    overrideOptions?: Options
  ) {
    const controls = component.getAttribute('aria-controls');

    // Protect the programmer and for the existance of the attribute that points
    // to the correct target.
    if (!controls) {
      throw new Error('Expected [aria-controls] on the component');
    }

    // If we were to use document.getElementById, we would not be able to use
    // Toggleable when we don't have a document, such as during turbolinks
    // events (where data.newBody is not attached to a document).
    this.target = component
      .closest('body')!
      .querySelector<HTMLElement>(`#${controls}`)!;

    if (!this.target) {
      throw new Error(
        `Element controlled by toggleable (#${controls}) not found on page`
      );
    }

    const dataOptions: Partial<Options> = JSON.parse(
      component.getAttribute('data-toggleable') || '{}'
    );

    const {
      form: withForm,
      className,
      event,
    } = {
      form: false,
      className: '',
      ...dataOptions,
      ...overrideOptions,
    };

    if (withForm !== false) {
      this.form =
        component.closest<HTMLFormElement>('form[data-remote]') || undefined;
      this.formQueryParam =
        typeof withForm === 'string' ? withForm : 'collapsed';
    }

    this.className = className;
    this.event = event;

    this.toggle = this.toggle.bind(this);
    this.destroy = this.destroy.bind(this);

    this.onToggleEvent = this.onToggleEvent.bind(this);

    this.component.addEventListener('click', this.toggle);

    if (this.event) {
      emitter.on(this.event, this.onToggleEvent);
    }
  }

  public get collapsed(): boolean {
    return this.component!.getAttribute('aria-expanded') === 'false';
  }

  public get expanded(): boolean {
    return this.component.getAttribute('aria-expanded') === 'true';
  }

  public destroy(): void {
    this.component.removeEventListener('click', this.toggle);

    if (this.event) {
      emitter.off(this.event, this.onToggleEvent);
    }
  }

  private onToggleEvent(event?: ToggleEvent): void {
    if (!event) {
      return;
    }

    setTimeout(() => {
      const { collapsed } = event;

      if (this.collapsed === collapsed) {
        return;
      }

      this.toggle();
    }, 0);
  }

  public toggle(e?: Event): void {
    // We don't want to prevent the default (which might be clicking a button)
    // but we do want to stop this event from propagating up the tree. We don't
    // want to stop propagating to ourself (.stopImmediatePropagation) because
    // there might be more event handlers on this component.
    e?.stopPropagation();

    const wasActive = this.expanded;
    const isActive = !wasActive;

    toggleExpanded(this.component, isActive);

    if (this.className !== '') {
      this.target.classList.toggle(this.className, !isActive);
    } else {
      toggleHidden(this.target, !isActive);
    }

    if (this.form) {
      const form = this.form;

      const apply = (): void => {
        const action = form.action;
        const [path] = action.split('?');

        form.setAttribute(
          'action',
          `${path}?${this.formQueryParam!}=${String(isActive)}`
        );
      };

      if (e) {
        // Ensure we don't change the form action until the click event has
        // propgated to all event handlers. This allows the button to be
        // clicked, the form to be submitted, and then the action to change
        // (even before the response comes back).
        setTimeout(apply, 0);
      } else {
        // Not a user action, so no propagation, so no need to move this to the
        // back of the queue.
        apply();
      }
    }

    const focusTarget = isActive ? this.target : this.component;
    focusTarget.focus();
  }
}

export function toggleables(): void {
  upgrade('[data-toggleable]');
}

export function toggleable(component: HTMLElement): Toggleable {
  return new Toggleable(component);
}

register('[data-toggleable]', ({ element }) => toggleable(element).destroy);
