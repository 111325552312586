import { register } from 'src/brandless/ujs';
import { emitter } from 'src/brandless/emitter';
import { default as consumer } from 'src/channels/consumer';

register('[data-nav-notifications-count]', ({ element }) => {
  console.log('[initialized] nav notification counter');

  const notificationsUrl = element.getAttribute('data-nav-notifications-url');

  const setNotificationsCount = (): void => {
    if (!notificationsUrl) {
      return;
    }

    fetch(notificationsUrl, {
      headers: {
        accept: 'application/vnd.wondr.notifications-page.v1+json',
      },
    })
      .then((response: Response) => {
        if (response.ok) {
          return response.json();
        }
      })
      .then(({ notifications: { _embedded } }) => {
        const unreadCount = _embedded.filter(
          (notification: Record<string, unknown>) => notification.is_unread
        ).length;

        element.setAttribute('data-count', unreadCount?.toString());
      });
  };

  // Set initial count
  setNotificationsCount();

  emitter.on('notifications:refreshed', setNotificationsCount);

  return function deregister(): void {
    emitter.off('notifications:refreshed', setNotificationsCount);
  };
});
