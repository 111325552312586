import { register } from 'src/brandless/ujs';

register(
  '[data-component="search-container/mobile-menu-btn"]',
  ({ element }) => {
    const navigation = document.querySelector('[data-component="navigation"]');
    const mainApp = document.querySelector('[data-target="main"]');

    if (!navigation) {
      return;
    }

    const showNavigation = (): void => {
      navigation.classList.toggle('--display');
      mainApp?.classList.toggle('pointer-events-none');
      document.body.classList.toggle('overflow-hidden');
    };

    const onOutsideClick = (e: MouseEvent): void => {
      if (!(e.target instanceof HTMLElement)) {
        return;
      }

      // Click was inside
      if (e.target.closest('.navigation')) {
        return;
      }

      mainApp?.classList.remove('pointer-events-none');
      navigation.classList.remove('--display');
      document.body.classList.remove('overflow-hidden');
    };

    document.addEventListener('click', onOutsideClick);
    element.addEventListener('click', showNavigation);

    return function deregister(): void {
      document.removeEventListener('click', onOutsideClick);
      element.removeEventListener('click', showNavigation);
    };
  }
);
