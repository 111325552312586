export const KEY_CODES = Object.freeze({
  BACKSPACE: 8,
  TAB: 9,
  RETURN: 13,
  ESC: 27,
  SPACE: 32,
  PAGE_UP: 33,
  PAGE_DOWN: 34,
  END: 35,
  HOME: 36,
  LEFT: 37,
  UP: 38,
  RIGHT: 39,
  DOWN: 40,
  DELETE: 46,
});

export const KEY_NAMES = Object.freeze({
  BACKSPACE: 'Backspace',
  TAB: 'Tab',
  RETURN: 'Enter',
  ESC: 'Escape',
  SPACE: ' ',
  PAGE_UP: 'PageUp',
  PAGE_DOWN: 'PageDown',
  END: 'End',
  HOME: 'Home',
  LEFT: 'ArrowLeft',
  UP: 'ArrowUp',
  RIGHT: 'ArrowRight',
  DOWN: 'ArrowDown',
  DELETE: 'Delete',
});

export const KEY_NAMES_LEGACY = Object.freeze({
  ESC: 'Esc',
  SPACE: 'Space',
  LEFT: 'Left',
  UP: 'Up',
  RIGHT: 'Right',
  DOWN: 'Down',
});

export function remove(item: Element): false | Element | void {
  if (item.remove && typeof item.remove === 'function') {
    return item.remove();
  }
  if (
    item.parentNode &&
    item.parentNode.removeChild &&
    typeof item.parentNode.removeChild === 'function'
  ) {
    return item.parentNode.removeChild(item);
  }
  return false;
}

export function isFocusable(
  element: HTMLElement | null | undefined,
  programmaticaly = false
): element is HTMLElement {
  if (!element) {
    return false;
  }

  if (
    element.tabIndex > 0 ||
    (element.tabIndex === 0 && element.getAttribute('tabIndex') !== null) ||
    (programmaticaly && element.getAttribute('tabIndex'))
  ) {
    // Hidden
    if (
      element.classList.contains('hidden') ||
      element.style.display === 'none' ||
      element.hasAttribute('hidden')
    ) {
      return false;
    }
    return true;
  }

  if ((element as HTMLButtonElement).disabled) {
    return false;
  }

  switch (element.nodeName) {
    case 'A':
      return (
        !!(element as HTMLAnchorElement).href &&
        (element as HTMLAnchorElement).rel != 'ignore'
      );
    case 'INPUT':
      return (
        (element as HTMLInputElement).type != 'hidden' &&
        (element as HTMLInputElement).type != 'file'
      );
    case 'BUTTON':
    case 'SELECT':
    case 'TEXTAREA':
      return true;
    default:
      return false;
  }
}
