/**
 * This content is based on
 *
 * - MenuButton.js,
 * - MenuItem.js and
 * - PopupMenuLinks.js,
 *
 * which are licensed according to the W3C Software License at
 * https://www.w3.org/Consortium/Legal/2015/copyright-software-and-document
 *
 * @see {MenuButon}
 * @see {Overflowable}
 */

import { KEY_NAMES, KEY_NAMES_LEGACY } from './aria';
import { toggleExpanded, toggleHidden, isPrintableCharacter } from './utils';

const FOCUS_NONE = Object.freeze({});
export const FOCUS_FIRST = Object.freeze({});
export const FOCUS_LAST = Object.freeze({});

const MENU_BACKDROP_CSS_CLASS = 'menu-backdrop';

/**
 * Object that configures menu item elements by setting tabIndex and registering
 * itself to handle pertinent events.
 *
 * While menuitem elements handle many keydown events, as well as focus and blur
 * events, they do not maintain any state variables, delegating those
 * responsibilities to its associated menu object.
 *
 * Consequently, it is only necessary to create one instance of MenuButtonItem
 * from within the menu object; its configure method can then be called on each
 * menuitem element.
 *
 * When creating a popup menu, there are rules to follow:
 *
 * 1. the button to toggle the menu must have an id
 * 2. the button to toggle the menu must have aria-haspopup="true"
 * 3. the button to toggle the menu must have aria-controls="..."
 *
 * If the button is actually an anchor element, its default action will be
 * prevented. This is a very usefull technique to create an overflow menu that
 * works as a link when JavaScript is disabled.
 *
 * If the contents of the button don't explain what will happen when it's
 * pressed, you must add aria-label="explanation".
 *
 * The popup menu also has some rules.
 *
 * 1. must have an id and match the value of aria-controls
 * 2. must have the hidden attribute
 * 3. when using a list, each li must have role="none"
 * 4. each list item can ONLY have one of the following:
 *    - anchor (a) element with role="menuitem"
 *    - button element with role="menuitem"
 *    - form (without role) with button element with role="menuitem"
 * 5. when NOT using a list, add role="menu" to the actual list.
 *
 * When you want a backdrop to close the menu, add MENU_BACKDROP_CSS_CLASS as
 * classname to the element that provides the backdrop.
 *
 * If these rules are followed, the menu can be initialised using the following
 * code, where component is the HTML Element that is the button that toggles:
 *
 *    new MenuButton(component)
 *
 * @example
 *
 * <button id="comment-1-overflow-menubutton"
 *         type="button"
 *         data-overflow="menu"
 *         aria-haspopup="true"
 *         aria-controls="comment-1-overflow-menu"
 *         >
 *         Toggle comment menu
 * </button>
 *
 * <ul id="comment-id-overflow-menu"
 *     aria-label="Comment menu"
 *     hidden
 *     >
 *
 *     <li role="none">
 *        <a href="/users/1" role="menuitem">
 *          See author
 *        </a>
 *     </li>
 *
 *     <li role="none">
 *        <form action="/posts/1/comments/1" method="delete" data-remote>
 *          <button type="submit" role="menuitem">
 *            Delete comment
 *          </button>
 *        </form>
 *     </li>
 * </ul>
 */
export class MenuButton {
  private readonly domNode: HTMLElement;

  private popupMenu: PopupMenuLinks | false;
  private hasHover: boolean;

  public openOnHover = false;
  public closeOnMouseOut = false;

  /**
   *
   * @param domNode The DOM element node that serves as the menu item container.
   *    The menuObj PopupMenu is responsible for checking that it has requisite
   *    metadata, e.g. role="menuitem".
   */
  constructor(
    domNode: HTMLElement,
    private onExpandedChanged = (_: boolean): void => {
      /** noop */
    }
  ) {
    this.domNode = domNode;
    this.popupMenu = false;

    this.hasHover = false;

    this.open = this.open.bind(this);
    this.close = this.close.bind(this);
    this.focus = this.focus.bind(this);
    this.destroy = this.destroy.bind(this);

    this.handleKeydown = this.handleKeydown.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleFocus = this.handleFocus.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
    this.handleMouseover = this.handleMouseover.bind(this);
    this.handleMouseout = this.handleMouseout.bind(this);

    this.domNode.setAttribute('aria-haspopup', 'true');

    this.domNode.addEventListener('keydown', this.handleKeydown);
    this.domNode.addEventListener('click', this.handleClick);
    this.domNode.addEventListener('focus', this.handleFocus);
    this.domNode.addEventListener('blur', this.handleBlur);
    this.domNode.addEventListener('mouseover', this.handleMouseover);
    this.domNode.addEventListener('mouseout', this.handleMouseout);

    // initialize pop up menu
    const menuNode = document.getElementById(
      this.domNode.getAttribute('aria-controls') || ''
    );

    if (menuNode) {
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      this.popupMenu = new PopupMenuLinks(menuNode, this);
    }
  }

  public destroy(): void {
    this.domNode.removeEventListener('keydown', this.handleKeydown);
    this.domNode.removeEventListener('click', this.handleClick);
    this.domNode.removeEventListener('focus', this.handleFocus);
    this.domNode.removeEventListener('blur', this.handleBlur);
    this.domNode.removeEventListener('mouseover', this.handleMouseover);
    this.domNode.removeEventListener('mouseout', this.handleMouseout);

    if (this.popupMenu) {
      this.popupMenu.destroy();
    }
  }

  public set closeOnBlur(next: boolean) {
    if (this.popupMenu) {
      this.popupMenu.closeOnBlur = next;
    }
  }

  /**
   * Return true if the attached popup menu has focus.
   */
  public get focused(): boolean {
    return this.popupMenu && this.popupMenu.hasFocus;
  }

  public get hovered(): boolean {
    return this.hasHover;
  }

  /***
   * If true, screen readers will consider the attached menu as active
   */
  public get expanded(): boolean {
    return this.domNode.getAttribute('aria-expanded') == 'true';
  }

  /**
   * Set the screen reader expanded state
   */
  public set expanded(flag: boolean) {
    toggleExpanded(this.domNode, flag);
    this.onExpandedChanged(flag);
  }

  /**
   * Get the button's accessibility label
   */
  public get label(): string {
    return this.domNode.textContent || '';
  }

  public open(
    focus: typeof FOCUS_NONE | typeof FOCUS_FIRST | typeof FOCUS_LAST
  ): void {
    if (!this.popupMenu) {
      return;
    }

    this.popupMenu.open();

    if (focus === FOCUS_LAST) {
      this.popupMenu.setFocusToLastItem();
    } else if (focus === FOCUS_FIRST) {
      this.popupMenu.setFocusToFirstItem();
    }
  }

  public close(force: boolean): void {
    if (!this.popupMenu) {
      return;
    }

    this.popupMenu.close(force);
  }

  public focus(): void {
    this.domNode.focus();
  }

  private handleKeydown(event: KeyboardEvent): void {
    let handled = false;

    /**
     * Accessibility
     *
     * Please see Menu Button WAI-ARIA practices article for details on recommended Roles, States, and Properties for
     * menu button (button that opens a menu). [https://www.w3.org/TR/wai-aria-practices/#menubutton].
     *
     * With focus on the menu button:
     *
     * - Enter, Space & Down Arrow opens the menu and places focus on the first menu item.
     * - Up Arrow opens the menu and moves focus to the last menu item.
     * - The focus is set to list root element (where role="menu" is set) when clicked or touched.
     */

    switch (event.key) {
      case KEY_NAMES_LEGACY.SPACE:
      case KEY_NAMES_LEGACY.DOWN:
      case KEY_NAMES.SPACE:
      case KEY_NAMES.RETURN:
      case KEY_NAMES.DOWN:
        if (this.popupMenu) {
          this.open(FOCUS_FIRST);
        }

        handled = true;
        break;

      case KEY_NAMES_LEGACY.UP:
      case KEY_NAMES.UP:
        if (this.popupMenu) {
          this.open(FOCUS_LAST);
          handled = true;
        }

        break;

      default:
        break;
    }

    if (handled) {
      event.stopPropagation();
      event.preventDefault();
    }
  }

  private handleClick(e: Event): void {
    if (this.domNode.tagName === 'A' && e.currentTarget === this.domNode) {
      e.preventDefault();
    }

    if (this.expanded) {
      this.close(true);
    } else {
      this.open(FOCUS_FIRST);
    }
  }

  private handleFocus(): void {
    if (!this.popupMenu) {
      return;
    }

    this.popupMenu.hasFocus = true;
  }

  private handleBlur(): void {
    if (!this.popupMenu) {
      return;
    }

    this.popupMenu.hasFocus = false;
  }

  private handleMouseover(): void {
    this.hasHover = true;

    if (this.openOnHover) {
      this.open(FOCUS_NONE);
    }
  }

  private handleMouseout(): void {
    this.hasHover = false;

    if (this.closeOnMouseOut) {
      setTimeout(() => this.close(false), 300);
    }
  }
}

/**
 * Wrapper object for a simple menu item in a popup menu
 */
export class MenuItem {
  private readonly domNode: HTMLElement;
  private readonly menu: PopupMenuLinks;

  public openOnHover = false;
  public closeOnMouseOut = false;
  public closeOnBlur = true;

  /**
   *
   * @param domNode The DOM element node that serves as the menu item container.
   *    The menuObj PopupMenu is responsible for checking that it has requisite
   *    metadata, e.g. role="menuitem".
   * @param menuObj The object that is a wrapper for the PopupMenu DOM element
   *    that contains the menu item DOM element.
   */
  constructor(domNode: HTMLElement, menuObj: PopupMenuLinks) {
    this.domNode = domNode;
    this.menu = menuObj;

    this.domNode.tabIndex = -1;

    if (!this.domNode.getAttribute('role')) {
      this.domNode.setAttribute('role', 'menuitem');
    }

    this.handleKeydown = this.handleKeydown.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleFocus = this.handleFocus.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
    this.handleMouseover = this.handleMouseover.bind(this);
    this.handleMouseout = this.handleMouseout.bind(this);

    this.domNode.addEventListener('keydown', this.handleKeydown);
    this.domNode.addEventListener('click', this.handleClick);
    this.domNode.addEventListener('focus', this.handleFocus);
    this.domNode.addEventListener('blur', this.handleBlur);
    this.domNode.addEventListener('mouseover', this.handleMouseover);
    this.domNode.addEventListener('mouseout', this.handleMouseout);
  }

  public destroy(): void {
    this.domNode.removeEventListener('keydown', this.handleKeydown);
    this.domNode.removeEventListener('click', this.handleClick);
    this.domNode.removeEventListener('focus', this.handleFocus);
    this.domNode.removeEventListener('blur', this.handleBlur);
    this.domNode.removeEventListener('mouseover', this.handleMouseover);
    this.domNode.removeEventListener('mouseout', this.handleMouseout);
  }

  public get focused(): boolean {
    return this.menu.hasFocus;
  }

  public get hovered(): boolean {
    return this.menu.hasHover;
  }

  public get keyboardChar(): string {
    const textContent = (this.domNode.textContent || '').trim();
    return textContent.substring(0, 1).toLowerCase();
  }

  public get focusable(): boolean {
    if (this.domNode.style.display === 'none') {
      return false;
    }

    if (this.domNode.closest('[hidden], .hidden, [aria-hidden="true"]')) {
      return false;
    }

    if ((this.domNode as HTMLButtonElement).disabled) {
      return false;
    }

    // This is true because we know for a fact that the element is an anchor,
    // or button. We could additionally check if it's an item with a tabindex
    // or that the link has a valid href, but that's outside the scope right
    // now.
    return true;
  }

  public focus(): void {
    this.domNode.focus();
  }

  private handleKeydown(event: KeyboardEvent): void {
    let handled = false;
    const char = event.key;

    if (
      event.ctrlKey ||
      event.altKey ||
      event.metaKey ||
      event.key === KEY_NAMES.SPACE ||
      event.key === KEY_NAMES_LEGACY.SPACE ||
      event.key === KEY_NAMES.RETURN
    ) {
      return;
    }

    if (event.shiftKey) {
      if (isPrintableCharacter(char)) {
        this.menu.setFocusByFirstCharacter(this, char);
        handled = true;
      }

      if (event.key === KEY_NAMES.TAB) {
        this.menu.setFocusToController();
        this.menu.close(true);
      }
    } else {
      switch (event.key) {
        case KEY_NAMES_LEGACY.ESC:
        case KEY_NAMES.ESC:
          this.menu.setFocusToController();
          this.menu.close(true);
          handled = true;
          break;

        case KEY_NAMES_LEGACY.UP:
        case KEY_NAMES.UP:
          this.menu.setFocusToPreviousItem(this);
          handled = true;
          break;

        case KEY_NAMES_LEGACY.DOWN:
        case KEY_NAMES.DOWN:
          this.menu.setFocusToNextItem(this);
          handled = true;
          break;

        case KEY_NAMES.HOME:
        case KEY_NAMES.PAGE_UP:
          this.menu.setFocusToFirstItem();
          handled = true;
          break;

        case KEY_NAMES.END:
        case KEY_NAMES.PAGE_DOWN:
          this.menu.setFocusToLastItem();
          handled = true;
          break;

        case KEY_NAMES.TAB:
          this.menu.setFocusToController();
          this.menu.close(true);
          break;

        default:
          if (isPrintableCharacter(char)) {
            this.menu.setFocusByFirstCharacter(this, char);
          }
          break;
      }
    }

    if (handled) {
      event.stopPropagation();
      event.preventDefault();
    }
  }

  public open(): void {
    this.menu.open();
  }

  public close(flag: boolean): void {
    this.menu.close(flag);
  }

  private handleClick(): void {
    this.menu.setFocusToController();
    this.close(true);
  }

  private handleFocus(): void {
    this.menu.hasFocus = true;
  }

  private handleBlur(): void {
    this.menu.hasFocus = false;

    if (this.closeOnBlur) {
      setTimeout(() => this.close(false), 300);
    }
  }

  private handleMouseover(): void {
    this.menu.hasHover = true;

    if (this.openOnHover) {
      this.open();
    }
  }

  private handleMouseout(): void {
    this.menu.hasHover = false;

    if (this.closeOnMouseOut) {
      setTimeout(() => this.close(false), 300);
    }
  }
}

/**
 * Wrapper object for a simple popup menu (without nested submenus)
 */
export class PopupMenuLinks {
  public hasFocus = false;
  public hasHover = false;

  private readonly menuitems: MenuItem[];
  private readonly firstChars: string[];
  private readonly firstItem: MenuItem | null;
  private readonly lastItem: MenuItem | null;

  private readonly menuNode: HTMLElement;
  private readonly backdrop: HTMLElement | null | undefined;
  private readonly mouseNode: HTMLElement;

  /**
   *
   * @param domNode The DOM element node that serves as the popup menu
   *    container. Each child element of domNode that represents a menuitem must
   *    have a 'role' attribute with value 'menuitem'.
   * @param controller The object that is a wrapper for the DOM element that
   *    controls the menu, e.g. a button element, with an 'aria-controls'
   *    attribute that references this menu's domNode.
   *
   *    The controller object is expected to have the following properties:
   *
   *    1. domNode: The controller object's DOM element node, needed for
   *       retrieving positioning information.
   *    2. hasHover: boolean that indicates whether the controller object's
   *       domNode has responded to a mouseover event with no subsequent
   *       mouseout event having occurred.
   */
  constructor(
    private readonly domNode: HTMLElement,
    private readonly controller: MenuButton
  ) {
    const msgPrefix = 'PopupMenuLinks constructor argument domNode ';

    if (!(domNode instanceof Element)) {
      throw new TypeError(msgPrefix + 'is not a DOM Element.');
    }

    this.menuNode =
      domNode.hasAttribute('[role="menu"]') || domNode.tagName === 'UL'
        ? domNode
        : domNode.querySelector<HTMLElement>('[role="menu"]')!;

    if (this.menuNode.childElementCount === 0) {
      throw new Error(msgPrefix + 'has no element children.');
    }

    // Protect the programmer from injecting elements that are currently not
    // handled by this script. The menu items (descendants) may ONLY be one of
    // the following items:
    //
    // - a (anchor)
    // - button
    // - form containing a button
    //
    let childElement = this.menuNode.firstElementChild;
    while (childElement) {
      const menuitem = childElement.firstElementChild;
      if (
        menuitem &&
        menuitem.tagName !== 'A' &&
        menuitem.tagName !== 'BUTTON' &&
        menuitem.tagName !== 'FORM'
      ) {
        throw new Error(
          msgPrefix +
            'has descendant elements that are not A or button elements.'
        );
      }
      childElement = childElement.nextElementSibling;
    }

    this.menuitems = [];
    this.firstChars = [];

    this.firstItem = null;
    this.lastItem = null;

    this.hasFocus = false;
    this.hasHover = false;

    // Configure the domNode itself.
    this.menuNode.tabIndex = -1;
    this.menuNode.setAttribute('role', 'menu');

    // Ensure that this menu has some accessible label so that screenreaders
    // will correctly announce the menu.
    if (
      !this.menuNode.getAttribute('aria-labelledby') &&
      !this.menuNode.getAttribute('aria-label') &&
      !this.menuNode.getAttribute('title')
    ) {
      const label = this.controller.label;
      this.menuNode.setAttribute('aria-label', label);
    }

    this.backdrop = this.domNode.classList.contains(MENU_BACKDROP_CSS_CLASS)
      ? domNode
      : domNode.querySelector<HTMLElement>(`.${MENU_BACKDROP_CSS_CLASS}`);

    this.mouseNode =
      this.backdrop === this.domNode ? this.menuNode : this.domNode;

    this.handleMouseover = this.handleMouseover.bind(this);
    this.handleMouseout = this.handleMouseout.bind(this);
    this.handleBackdrop = this.handleBackdrop.bind(this);

    this.mouseNode.addEventListener('mouseover', this.handleMouseover);
    this.mouseNode.addEventListener('mouseout', this.handleMouseout);

    if (this.backdrop) {
      this.backdrop.addEventListener('click', this.handleBackdrop);
    }

    let menuElement: Element | null;
    childElement = this.menuNode.firstElementChild;

    while (childElement) {
      menuElement = childElement.firstElementChild;

      while (menuElement) {
        // If the item has no role, but a descendant does, add the descendant
        // instead. This is the case for a form containing a button. Keyboard
        // users only care about focusable items.
        let itemElement: Element | null = null;
        if (!menuElement.getAttribute('role')) {
          const inner = menuElement.querySelector('[role="menuitem"]');
          if (inner) {
            itemElement = inner;
          }
        }

        if (
          menuElement.tagName === 'FORM' &&
          menuElement.querySelector('button')
        ) {
          itemElement = menuElement.querySelector('button');
        }

        if (!itemElement) {
          itemElement = menuElement;
        }
        const menuItem = new MenuItem(itemElement as HTMLElement, this);

        this.menuitems.push(menuItem);

        // Grab the keyboard character for quick access via the keyboard
        this.firstChars.push(menuItem.keyboardChar);

        if (menuElement) {
          menuElement = menuElement.nextElementSibling;
        }
      }

      childElement = childElement.nextElementSibling;
    }

    // Use populated menuitems array to initialize firstItem and lastItem.
    const numItems = this.menuitems.length;

    if (numItems > 0) {
      this.firstItem = this.menuitems[0];
      this.lastItem = this.menuitems[numItems - 1];
    } else {
      console.warn(`PopupMenuLinks ${this.domNode.id} has 0 menu items`);
    }
  }

  public destroy(): void {
    this.menuitems.forEach((item) => item.destroy());

    this.mouseNode.removeEventListener('mouseover', this.handleMouseover);
    this.mouseNode.removeEventListener('mouseout', this.handleMouseout);

    if (this.backdrop) {
      this.backdrop.removeEventListener('click', this.handleBackdrop);
    }
  }

  public set closeOnBlur(next: boolean) {
    this.menuitems.forEach((item) => (item.closeOnBlur = next));
  }

  public set closeOnMouseOut(next: boolean) {
    this.menuitems.forEach((item) => (item.closeOnMouseOut = next));
  }

  private handleMouseover(): void {
    this.hasHover = true;
  }

  private handleMouseout(): void {
    this.hasHover = false;

    setTimeout(() => this.close(false), 300);
  }

  private handleBackdrop(e: Event): void {
    if (e.target !== e.currentTarget) {
      return;
    }

    e.preventDefault();
    this.close(true);
  }

  public setFocusToController(): void {
    this.controller.focus();
  }

  public setFocusToFirstItem(): void {
    if (!this.firstItem) {
      return;
    }

    this.firstItem.focus();
  }

  public setFocusToLastItem(): void {
    if (!this.lastItem) {
      return;
    }

    this.lastItem.focus();
  }

  public setFocusToPreviousItem(currentItem: MenuItem): void {
    let index =
      currentItem === this.firstItem
        ? this.menuitems.indexOf(this.lastItem!) + 1
        : this.menuitems.indexOf(currentItem);

    // Skip through hidden items
    while (index > 0) {
      const element = this.menuitems[index - 1];
      if (!element.focusable) {
        index -= 1;
        continue;
      }

      element.focus();
      break;
    }
  }

  public setFocusToNextItem(currentItem: MenuItem): void {
    const count = this.menuitems.length;

    let index =
      currentItem === this.lastItem
        ? this.menuitems.indexOf(this.firstItem!) - 1
        : this.menuitems.indexOf(currentItem);

    while (index < count) {
      const element = this.menuitems[index + 1];
      if (!element.focusable) {
        index += 1;
        continue;
      }

      element.focus();
      break;
    }
  }

  public setFocusByFirstCharacter(currentItem: MenuItem, char: string): void {
    char = char.toLowerCase();

    // Get start index for search based on position of currentItem
    let start = this.menuitems.indexOf(currentItem) + 1;
    if (start === this.menuitems.length) {
      start = 0;
    }

    // Check remaining slots in the menu
    let index = this.getIndexFirstChars(start, char);

    // If not found in remaining slots, check from beginning
    if (index === -1) {
      index = this.getIndexFirstChars(0, char);
    }

    // If match was found...
    if (index > -1) {
      this.menuitems[index].focus();
    }
  }

  public getIndexFirstChars(startIndex: number, char: string): number {
    for (let i = startIndex; i < this.firstChars.length; i++) {
      if (char === this.firstChars[i]) {
        if (this.menuitems[i].focusable) {
          return i;
        }
      }
    }
    return -1;
  }

  public open(): void {
    toggleHidden(this.domNode, false);

    // set aria-expanded attribute
    this.controller.expanded = true;
  }

  public close(force: boolean): void {
    if (
      force ||
      (!this.hasFocus && !this.hasHover && !this.controller.hovered)
    ) {
      toggleHidden(this.domNode, true);
      this.controller.expanded = false;
    }
  }
}
