import { onPageLoad } from './spa';
import type { OnNavigate } from './spa';
import { Toggleable } from './toggleable';
import { default as consumer } from 'src/channels/consumer';
import { createConsumer } from '@rails/actioncable';

function getToggleComponents(
  root: Document | HTMLElement
): [
  HTMLElement | null,
  HTMLElement | null,
  HTMLElement | null,
  HTMLElement | null
] {
  return [
    root.querySelector('[data-action="toggle-topics"]'),
    root.querySelector('[data-action="toggle-network"]'),
    root.querySelector('[data-action="toggle-channels"]'),
    root.querySelector('[data-action="toggle-workspaces'),
  ];
}

function initialize(): OnNavigate | void {
  const consumer = createConsumer();
  consumer.subscriptions.create('RingingChannel', {
    connected() {
      console.log('Connected to the RingingChannel');
    },
    received(data: string) {
      const container = document.querySelector("[data-target='ringing-modal']");
      if (container) {
        container.innerHTML = data;
        container.classList.remove('hidden');
      }
      setUpDeclineButton();
    },
  });

  function setUpDeclineButton(): void {
    const modal = document.querySelector("[data-target='ringing-modal']");
    const declineButton = document.querySelector(
      "[data-target='ringing-modal-decline-button']"
    );

    declineButton?.addEventListener('click', () => {
      modal?.classList.add('hidden');
    });
  }

  const component = document.querySelector<HTMLElement>(
    '[data-component="navigation"]'
  );
  if (!component) {
    return;
  }

  const [
    toggleTopicsAction,
    toggleNetworkAction,
    toggleChannelsAction,
    toggleWorkspacesAction,
  ] = getToggleComponents(component);

  // toggleAction.addEventListener('click', onToggle);
  // const toggle = new Toggleable(toggleAction, {
  //   form: 'collapsed',
  //   event: 'settings:sidebar',
  //   className: 'collapsed',
  // });

  // "Your Topics" won't exist when not logged in
  const toggleTopics = toggleTopicsAction
    ? new Toggleable(toggleTopicsAction, {
        form: true,
        event: 'settings:sidebar:topics',
      })
    : undefined;

  // "Your Network" won't exist when not logged in
  const toggleNetwork = toggleNetworkAction
    ? new Toggleable(toggleNetworkAction, {
        form: true,
        event: 'settings:sidebar:network',
      })
    : undefined;

  // "Your Channels" only exist if logged in with more than 0 channel as admin
  const toggleChannels = toggleChannelsAction
    ? new Toggleable(toggleChannelsAction, {
        form: true,
        event: 'settings:sidebar:channels',
      })
    : undefined;

  // "Your Workspaces" won't exist when not logged in
  const toggleWorkspaces = toggleWorkspacesAction
    ? new Toggleable(toggleWorkspacesAction, {
        form: true,
        event: 'settings:sidebar:workspaces',
      })
    : undefined;

  return function prepareNextPage(_, newBody): void {
    const nextComponent = newBody.querySelector<HTMLElement>(
      '[data-component="navigation"]'
    );

    // When the next body also has a sidebar, this will change its HTML to match
    // the current state of the sidebar. This is important when the next body
    // comes from a render cache, for example when pressing back/forward.
    //
    // The reason this is preffered is because the following might happen:
    //
    // - page is rendered with expanded: true
    // - page is cached
    // - user collapses sidenav
    // - user navigates
    // - next page is rendered with expanded: false
    //
    // Then, if the user goes back, the content will "flash" in the incorrect
    // style.
    //
    // - user pressed back
    // - page is retrieved from cache expanded: true
    // - page is rendered with expanded: false
    //
    if (nextComponent) {
      // const isExpanded = toggle.expanded;
      const hasExpandedTopics = toggleTopics?.expanded;
      const hasExpandedNetwork = toggleNetwork?.expanded;
      const hasExpandedChannels = toggleChannels?.expanded;
      const hasExpandedWorkspaces = toggleChannels?.expanded;

      const [
        nextToggleTopicsAction,
        nextToggleNetworkAction,
        nextToggleChannelsAction,
        nextToggleWorkspacesAction,
      ] = getToggleComponents(nextComponent);

      // const nextToggle = new Toggleable(nextToggleAction, {
      //   form: 'collapsed',
      //   className: 'collapsed',
      // });

      const nextToggleTopics = nextToggleTopicsAction
        ? new Toggleable(nextToggleTopicsAction, {
            form: true,
          })
        : undefined;

      const nextToggleNetwork = nextToggleNetworkAction
        ? new Toggleable(nextToggleNetworkAction, {
            form: true,
          })
        : undefined;

      const nextToggleChannels = nextToggleChannelsAction
        ? new Toggleable(nextToggleChannelsAction, {
            form: true,
          })
        : undefined;

      const nextToggleWorkspaces = nextToggleWorkspacesAction
        ? newToggleable(nextToggleWorkspacesAction, { form: true })
        : undefined;

      // Update the state of all the next-toggles, to match the current ones
      // if (nextToggle.expanded !== isExpanded) {
      //   nextToggle.toggle();
      // }

      // When "hasExpandedTopics" is undefined, the current sidebar lacks the
      // "Your Topics" section. The next component might have it, but nothing to
      // check in that case.
      if (
        nextToggleTopics &&
        hasExpandedTopics !== undefined &&
        nextToggleTopics.expanded !== hasExpandedTopics
      ) {
        nextToggleTopics.toggle();
      }

      // When "hasExpandedNetwork" is undefined, the current sidebar lacks the
      // "Your Network" section. The next component might have it, but nothing
      // to check in that case.
      if (
        nextToggleNetwork &&
        hasExpandedNetwork !== undefined &&
        nextToggleNetwork.expanded !== hasExpandedNetwork
      ) {
        nextToggleNetwork.toggle();
      }

      // When "hasExpandedChannels" is undefined, the current sidebar lacks the
      // "Your Channels" section. The next component might have it, but nothing
      // to check in that case.
      if (
        nextToggleChannels &&
        hasExpandedChannels !== undefined &&
        nextToggleChannels.expanded !== hasExpandedChannels
      ) {
        nextToggleChannels.toggle();
      }

      // Cleanup. These toggles will be (re)-created during the following
      // turbolinks:load event.
      // nextToggle.destroy();
      nextToggleTopics?.destroy();
      nextToggleNetwork?.destroy();
      nextToggleChannels?.destroy();
      nextToggleWorkspaces?.destroy();
    }

    // toggle.destroy();
    toggleTopics?.destroy();
    toggleNetwork?.destroy();
    toggleChannels?.destroy();
    toggleWorkspaces?.destroy();
  };
}

onPageLoad(initialize);
