import { QuickNotifications } from './components/QuickNotifications';
import { onPageLoad } from './spa';

function initialize(): void | (() => void) {
  const notificationsTrigger = document.querySelector<
    HTMLButtonElement | HTMLAnchorElement
  >(
    'a[data-component="notifications-toggle"], button[data-component="notifications-toggle"]'
  );
  const notificationsTray = document.querySelector<HTMLElement>(
    '[data-component="quick-notifications"]'
  );

  if (notificationsTray && notificationsTrigger) {
    const quickNotifications = new QuickNotifications(
      notificationsTray,
      notificationsTrigger
    );

    return function cleanUp(): void {
      quickNotifications.destroy();
    };
  }
}

onPageLoad(initialize);
