/**
 * @see {MenuButton}
 *
 * Similar to toggleable, but a proper aria-menu
 */

import { MenuButton } from './menu';
import { register, upgrade } from './ujs';

export function overflowables(): void {
  upgrade('[data-overflow="menu"]');
}

export function overflowable(component: HTMLElement): MenuButton {
  return new MenuButton(component);
}

register('#account-menubutton', ({ element }) => overflowable(element).destroy);
