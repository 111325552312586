import { register, upgradeAll } from 'src/brandless/ujs';
import { default as consumer } from 'src/channels/consumer';

register('[data-nav-rooms-unread-count]', ({ element }) => {
  const fetchUrl = element.getAttribute('data-fetch-url');
  const userId = element.getAttribute('data-user-id');

  if (!fetchUrl) {
    return;
  }

  const fetchCount = (): void => {
    fetch(fetchUrl)
      .then((response: Response) => {
        if (response.ok) {
          return response.json();
        }
      })
      .then((data: { unread_counter: { unread_posts_count: number } }) => {
        const { unread_posts_count: unreadPostsCount } = data.unread_counter;

        element.setAttribute('data-count', unreadPostsCount.toString());
      });
  };

  // Initialize unread count
  fetchCount();

  consumer.subscriptions.create(
    { channel: 'UnreadCounterChannel', id: Number(userId) },
    {
      connected() {
        console.log('Connected to UnreadCounterChannel!');
      },
      received() {
        fetchCount();
      },
    }
  );
});
