import mitt from 'mitt';
import type { EventType } from 'mitt';

export const emitter = mitt();
const BROADCAST_EVENT_KEY = 'broadcast.mitt';

/**
 * @todo use broadcast channel unless not available
 *       https://derk-jan.com/2020/05/cross-tab-events/
 */

/**
 * Emit an event on the current execution context (tab)
 *
 * @param type the event name
 * @param event optionally data to pass
 */
export const emit = emitter.emit.bind(emitter);

let lastNonce = '';

/**
 * Emit an event on all same-origin execution contexts (tabs)
 *
 * The event data must be JSON serializable
 *
 * @param type the event name
 * @param event optionally data to pass
 */
export const broadcast: typeof emitter['emit'] = <T = unknown>(
  type: EventType,
  event: T
) => {
  // Emit to this tab
  emit(type, event);

  // Emit to localstorage (so it can be consumed by other tabs)
  lastNonce = Math.random().toString(36);

  try {
    localStorage.setItem(
      BROADCAST_EVENT_KEY,
      JSON.stringify({
        type,
        data: event,
        nonce: lastNonce,
      })
    );
  } catch (_) {
    // Swallow localstorage errors and JSON errors
    //
    // In this case the event is not emitted across tabs, but has already
    // emitted to the current tab. Errors may be caused by privacy/storage
    // settings (e.g. Firefox), or out of storage.
  }
};

/**
 * This will trigger on storage events.
 *
 * That usually means that it will trigger in all browsing contexts that are
 * currently blurred (so not the current, active/focused one). This doesn't
 * hold for older browsers. IE10 for example will send the event to the calling
 * tab as well.
 *
 * @param event the storage event
 */
function handleStorageEvent(event: StorageEvent): void {
  if (event.key !== BROADCAST_EVENT_KEY || !event.newValue) {
    return;
  }

  const { type, data, nonce } = JSON.parse(event.newValue);

  // IE10 for example will send the event to the calling tab. This protects an
  // event from being emitted twice.
  if (lastNonce !== nonce) {
    emit(type, data);
  }
}

declare const window: Window & {
  emit: typeof emitter['emit'];
  broadcast: typeof emitter['emit'];
};

window.addEventListener('storage', handleStorageEvent);
window.emit = emit;
window.broadcast = broadcast;
